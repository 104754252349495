<template>
  <div :style="cssVariablesBinding">
    <Theme6BaseTheHeader />

    <main class="n-main-container">
      <slot />
    </main>

    <Theme6BaseTheFooter />
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia';
import IndexGlobal from '~/mixins/index.global';
import LayoutsMixin from '~/mixins/layouts.mixin';
import { useWebsiteStore } from '~/units/website/store';
import AvailableComponentsForTheme6 from '~/components/theme6/AvailableComponentsForTheme6.vue';
import { useAppMode } from '~/composables';

export default defineNuxtComponent({
  name: 'Theme6',

  mixins: [IndexGlobal, LayoutsMixin],

  components: { AvailableComponentsForTheme6 },

  data() {
    return {
      containers: [
        {
          name: 'BLOGS_VIEW',
          classes: 'blog-wrap5',
        },
        {
          name: 'BLOG_DETAIL_VIEW',
          classes: 'blog-wrap6',
        },
      ],
    };
  },

  head(context) {
    const appMode = useAppMode();

    if (appMode.server && context.ssrContext) {
      const websiteStore = useWebsiteStore();

      return {
        link: [
          {
            rel: 'stylesheet',
            href: `/theme6/css/${websiteStore.companyKey}/${websiteStore.companyKey}.css`,
            type: 'text/css',
            fetchPriority: 'low',
          },
          {
            rel: 'stylesheet',
            href: `/theme6/css/animate.min.css`,
            type: 'text/css',
          },
          {
            rel: 'stylesheet',
            href: `/theme6/css/odometer.css`,
            type: 'text/css',
          },
        ],
        script: [
          {
            src: `/theme6/js/vendor/jquery-3.6.0.min.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/jquery.odometer.min.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/jarallax-video.min.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/jquery.appear.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/jquery.easing.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/slick.min.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/ajax-form.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/wow.min.js`,
            type: 'text/javascript',
            async: false,
          },
          {
            src: `/theme6/js/main.js`,
            type: 'text/javascript',
            async: false,
          },
        ],
      };
    }
  },

  computed: {
    ...mapState(useWebsiteStore, ['currentPage']),

    cssVariablesBinding() {
      return {
        ...this.cssVariables,
        '--c-primary': this.colors.primary || '#000000',
      };
    },
  },
});
</script>
